<template>
  <div>
    <progress-bouncing center v-if="isLoading"/>
    <v-sheet v-else-if="basicImportCloned">
      <div class="text-left">
        <h1>Koble fil med Fixit</h1>
        <div class="mt-7">Vi har funnet x-antall kolonner i din fil. Vennligst velg hvilke kolonner du ønsker disse
          skal kobles til i Fixit.
        </div>
      </div>
      <v-form ref="form" v-model="formValid">
        <div v-if="missingMappings.length && !init">
          <v-alert type="error" dense text class="text-left">
            Missing required column mappings:
            <b v-for="(item, i) in missingMappings.map(x => x.column)" :key="i">
              {{ item }}<span v-if="missingMappings.length && i < missingMappings.length - 1">,</span>
            </b>
          </v-alert>
        </div>
        <div class="d-flex text-left justify-space-between mt-5 sticky">
          <div class="mr-auto pr-4"><b>Kolonner i Fixit:</b></div>
          <div class="mr-auto pl-15"><b>Kolonner i din fil:</b></div>
        </div>
        <div class="d-flex" v-for="(item, index) in fixitColumns" :key="index">
          <v-text-field style="width: 45%" dense background-color="rgba(211,211,211,0.5)" readonly :value="item.column"
                        class="text-overflow-ellipsis" outlined/>
          <v-icon style="width: 10%" class="align-self-start mt-2">mdi-arrow-right</v-icon>
          <v-select style="width: 45%"
                    :rules="[requiredColumnMapped(item.id) || 'Obligatorisk']"
                    placeholder="Select fixit column"
                    dense
                    @change="mapFixitColumn(item.id, $event)"
                    :items="basicImportCloned.parsedFileHeaders"
                    :value="basicImportCloned.parsedFileHeaders[mappedColumn(item.id)]"
                    outlined
                    clearable>
            <template v-slot:item="{item, on}">
              <v-list-item v-on="on" :disabled="columnMappingDisabled(item) || false">
                <div class="d-flex justify-space-between" style="width: 100%">
                  <div>{{ item }}</div>
                  <!--                  <v-tooltip bottom max-width="300" v-if="!columnMappingDisabled(item)">-->
                  <!--                    <template v-slot:activator="{on}">-->
                  <!--                      <v-btn icon v-on="on">-->
                  <!--                        <v-icon>mdi-information</v-icon>-->
                  <!--                      </v-btn>-->
                  <!--                    </template>-->
                  <!--                    <div>{{ item.columnDescription || item.column }}</div>-->
                  <!--                  </v-tooltip>-->
                </div>
              </v-list-item>
            </template>
          </v-select>
        </div>
      </v-form>
    </v-sheet>
  </div>
</template>

<script>

import ColumnMapping from "@/models/ColumnMapping";
import {waitFor} from "vue-wait/src/helpers";
import wizardMixin from "./wizard.mixin";
import cloneDeep from "lodash.clonedeep";

export default {
  name: "ColumnMapping",
  mixins: [wizardMixin],
  props: {
    isLoading: Boolean
  },
  data() {
    return {
      init: true,
      formValid: false,
      basicImportCloned: null,
      columnMappingsInital: {}
    }
  },
  computed: {
    requiredColumnMapped() {
      return columnId => {
        let mappedColumnIndex = this.mappedColumn(columnId)
        let isColumnRequired = this.fixitColumns.find(x => x.id === columnId)
        return mappedColumnIndex >= 0 || !isColumnRequired.isMappingRequired
      }
    },
    missingMappings() {
      let mappedColumn = this.basicImportCloned.columnMappings
      let fixitColumn = this.fixitColumns.filter(x => x.isMappingRequired)
      return fixitColumn.filter(r => !mappedColumn.map(x => x.fixitColumnId).includes(r.id))
    },
    columnMappingDisabled() {
      return item => this.basicImportCloned.columnMappings.find(x => item.id === x.fixitColumnId)?.fileColumnIndex >= 0
    },
    mappedColumn() {
      return columnId => this.basicImportCloned.columnMappings.find(x => x.fixitColumnId === columnId)?.fileColumnIndex
    },
    fixitColumns() {
      return this.$store.getters['basic-import/get']('fixitColumns')
          .sort((a, b) => {
            // sort by column name
            let x = a.column.toLowerCase();
            let y = b.column.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0
          })
          .sort((a, b) => (b.isMappingRequired === a.isMappingRequired) ? 0 : b.isMappingRequired ? 1 : -1)
      // return this.$store.getters['basic-import/get']('fixitColumns').sort((a, b) => {
      //   // sort by column name
      //   let x = a.column.toLowerCase();
      //   let y = b.column.toLowerCase();
      //   return x < y ? -1 : x > y ? 1 : 0
      // }).sort((a, b) => {
      //   // sort by selected/deselected items
      //   return (this.columnMappingDisabled(a) === this.columnMappingDisabled(b)) ? 0 : this.columnMappingDisabled(a) ? 1 : -1
      // })
    },
  },
  methods: {
    async formSubmit() {
      this.init = false

      await this.$refs.form.validate()
      if (!this.formValid || this.missingMappings.length) return

      let columnMappingsFormat = {}
      this.basicImportCloned.columnMappings.filter(x => x.fileColumnIndex >= 0 && x.fixitColumnId).forEach(x => {
        columnMappingsFormat[x.fixitColumnId] = x.fileColumnIndex
      })

      // if not dirty skip mapping
      // if (JSON.stringify(this.columnMappingsInital) === JSON.stringify(columnMappingsFormat))
      //   return true

      return await this.$store.dispatch('basic-import/mapColumns', {
        columnMappings: columnMappingsFormat,
        importId: this.basicImportCloned.id
      })
    },
    mapFixitColumn(fixitColumnId, fileColumn) {
      let fileColumnIndex = this.basicImportCloned.parsedFileHeaders.indexOf(fileColumn)
      let mapping = this.basicImportCloned.columnMappings.find(x => x.fixitColumnId === fixitColumnId)
      if (mapping)
        mapping.fileColumnIndex = fileColumnIndex
      else
        this.basicImportCloned.columnMappings.push(new ColumnMapping({fixitColumnId, fileColumnIndex}))
    }
  },
  async created() {
    await this.getActiveImport() // TODO
  },
  watch: {
    basicImport: {
      async handler(val) {
        if (val) {
          waitFor('loading basic-import-wizard column-mapping', async () => {
            let basicImport = cloneDeep(val)
            this.basicImportCloned = basicImport
            basicImport.columnMappings.filter(x => x.fileColumnIndex >= 0 && x.fixitColumnId).forEach(x => {
              this.columnMappingsInital[x.fixitColumnId] = x.fileColumnIndex
            })
            if (!Object.keys(this.columnMappingsInital).length) {
              // preselect file columns with the same name as fixit columns if any
              this.basicImportCloned.parsedFileHeaders.forEach((header) => {
                let fixitColumn = this.fixitColumns.find(x => x.column.toLowerCase() === header.trim().toLowerCase())
                if (fixitColumn)
                  this.mapFixitColumn(fixitColumn.id, header)
              })
            }
          }).apply(this)
        }
      }, immediate: true
    }
  }
}
</script>

<style scoped lang="scss">
.sticky {
  padding: 10px 0;
  position: sticky;
  top: 0;
  z-index: 100;
  background: white
}
</style>
